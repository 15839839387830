<template>
<div>
  <preview-gum/>
</div>
</template>

<script>
import PreviewGum from '@/components/previewGum.vue'
export default {
  components: {
    PreviewGum
  }
}
</script>

<style>

</style>
PreviewGum
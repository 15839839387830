<template>
<div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <div>Audio Input</div>
    <b-form-select inline size="sm" v-model="audioInput" :options="audioInputSelect"/>
  </div>
  <div class="form-inline sub-mt-2 sub-mr-2" v-if="false">
    <div>Audio Output</div>
    <b-form-select inline size="sm" v-model="audioOutput" :options="audioOutputSelect"/>
  </div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <div>Video</div>
    <b-form-select inline size="sm" v-model="video" :options="videoSelect"/>
  </div>
  <div class="mt-2 p-2 alert-light border rounded" v-if="showAdvancedSettings">
    <div class="form-inline sub-mt-2 sub-mr-2">
      <div>Video Definition</div>
      <b-form-select inline size="sm" v-model="videoDefinition" :options="videoDefinitionSelect"/>
    </div>
    <div class="form-inline sub-mt-2 sub-mr-2">
      <div>Audio Latency {{ audioLatency }}ms</div>
      <b-form-input style="width: 300px" v-model="audioLatency" type="range" min="0" step="10" max="1000"/>
    </div>
    <button class="btn btn-outline-danger mt-2" @click="resetAndSave()">Restore Default Settings and Save</button>
  </div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <button class="btn btn-primary" @click="save()">Save</button>
    <button class="btn btn-outline-primary" @click="showAdvancedSettings = !showAdvancedSettings">Advanced Settings</button>
    <button class="btn btn-secondary" @click="$emit('close')">Close</button>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('webrtc-settings');
import { stopStream } from "@/services/mediautils";

export default {
  props: {
    current: Object,
    config: {
      type: Object,
      default: () => { return {}; }
    }
  },
  data() {
    return {
      showAdvancedSettings: false,

      audioInput: null,
      audioOutput: null,
      video: null,
      videoDefinition: "auto",
      audioLatency: 0,

      audioInputSelect: [],
      audioOutputSelect: [],
      videoSelect: [],
      videoDefinitionSelect: [
        { text: "Automatic (Recommended)", value: "auto" },
        { text: "Highest", value: "high" },
        { text: "4K", value: {exact:"4K"} },
        { text: "1080p", value: {exact:"1080p"} },
        { text: "720p", value: {exact:"720p"} },
        { text: "480p", value: {exact:"480p"} },
        { text: "360p", value: {exact:"360p"} },
        { text: "Lowest", value: "low" },
      ],
    };
  },
  watch: {
    current() {
      this.setupCurrentSettings();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.enumerate();
      await this.setupCurrentSettings();
    },
    async setupCurrentSettings() {
      log.log("current settings=", this.current);
      Object.assign(this, this.current || {});
    },
    async resetAndSave() {
      if (!await this.$bvModal.msgBoxConfirm(`You will lose current settings, are you sure?`, {centered: true}))
        return;
      let obj = {
        audioInput: this.audioInputSelect[0]?.value,
        audioOutput: this.audioOutputSelect[0]?.value,
        video: this.videoSelect[0]?.value,
        videoDefinition: "auto",
        audioLatency: 0,
      };
      Object.assign(this, obj);
      this.save();
    },
    async enumerate() {
      let res = navigator.permissions?.query ? await navigator.permissions.query({ name: "camera" }) : null;
      if (this.config.skipPermission || res?.state != 'granted') {
        log.log("Creates a stream to request permissions from device, to be able to enumerate device names properly.");
        let stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
        stopStream(stream);
      }
      let dis = await navigator.mediaDevices.enumerateDevices();
      this.setupOptions(dis);
    },
    setupOptions(deviceInfos) {
      this.audioInputSelect = [];
      this.audioOutputSelect = [];
      this.videoSelect = [];
      // Handles being called several times to update labels. Preserve values.
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        this.$log.log("deviceInfo", deviceInfo);
        const option = {};
        option.value = deviceInfo.deviceId;
        if (deviceInfo.kind === 'audioinput') {
          option.text = deviceInfo.label || `microphone ${this.audioInputSelect.length + 1}`;
          this.audioInputSelect.push(option);
        } else if (deviceInfo.kind === 'audiooutput') {
          option.text = deviceInfo.label || `speaker ${this.audioOutputSelect.length + 1}`;
          this.audioOutputSelect.push(option);
        } else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `camera ${this.videoSelect.length + 1}`;
          this.videoSelect.push(option);
        } else {
          this.$log.log('Some other kind of source/device: ', deviceInfo);
        }
      }
      this.video = this.video || this.videoSelect[0]?.value;
      this.audioInput = this.audioInput || this.audioInputSelect[0]?.value;
      this.audioOutput = this.audioOutput || this.audioOutputSelect[0]?.value;
    },
    save() {
      let res =  {
        audioInput: this.audioInput,
        audioOutput: this.audioOutput,
        video: this.video,
        videoDefinition: this.videoDefinition,
        audioLatency: this.audioLatency,
      };
      log.log("selection-saved", res);
      this.$emit("selection-saved", res);
    }
  }
}
</script>

<style>

</style>